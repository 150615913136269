// import pkg from "../../package.json";

const pkg = {
  baseUrl: process.env.REACT_APP_BASE_URL
};

export const loginApi = `${pkg.baseUrl}user/logInUser`;
export const dasboardApi = `${pkg.baseUrl}user/dashboard`;
export const searchProduct = `${pkg.baseUrl}product/getProductSearchWebsite`;
export const filterProduct = `${pkg.baseUrl}product/getProductFilterWebsite`;
export const getGallery = `${pkg.baseUrl}gallery/getgallery`;

export const allProducts = `${pkg.baseUrl}product/getAllProductWebsite`;
export const getAllProductByCatWebsite = `${pkg.baseUrl}product/getAllProductByCatWebsite`;

export const getCategory = `${pkg.baseUrl}category/get-category-web/`;
export const homeApiByDisease = `${pkg.baseUrl}category/get-category-web/bydisease`;
export const homeApiMainCategory = `${pkg.baseUrl}category/get-category-web/maincategory`;
export const productCategoryApiByBrand = `${pkg.baseUrl}category/get-category-web/bybrand`;
export const singleProductApi = `${pkg.baseUrl}product/getSingleProductWebsite/`;
export const cartAndWishlistApi = `${pkg.baseUrl}product/checkWishlistedorcartproduct`;
export const mostsellingproduct = `${pkg.baseUrl}product/getMostViewProductWebsite`;

// wishlist apis
export const wishlistAddApi = `${pkg.baseUrl}user/addToWishlist`;
export const removeWishlistApi = `${pkg.baseUrl}user/removeFromWishlist/`;
export const getWishlistApi = `${pkg.baseUrl}user/getWishlist`;
// cart apis
export const cartAddApi = `${pkg.baseUrl}user/addToCart`;
export const removeCartApi = `${pkg.baseUrl}user/removeFromCart/`;
export const updateCartQuantity = `${pkg.baseUrl}user/update-item-quantity/`;
export const getCartApi = `${pkg.baseUrl}user/getCart`;
export const clearCartApi = `${pkg.baseUrl}user/clearCart`;

export const buyNowApi = `${pkg.baseUrl}user/buynow`;
export const fetchlabsApi = `${pkg.baseUrl}lab/getAllLab`;
export const getService = `${pkg.baseUrl}labservice/getLabService`;
export const bookingLab = `${pkg.baseUrl}user/booklab`;
export const singleLabUrl = `${pkg.baseUrl}lab/getSingleLab`;
export const contactEnquiry = `${pkg.baseUrl}user/contactUsform`;
export const appointmentBooking = `${pkg.baseUrl}user/conseltationform`;

export const orderHistoryApi = `${pkg.baseUrl}user/getAllProductOrderUser`;
export const orderCancelApi = `${pkg.baseUrl}user/cancelorder`;

export const googleAuth = `${pkg.baseUrl}user/googleAuth`;
export const subscribeApi = `${pkg.baseUrl}user/emailsubscribe`;

export const searchProductApi = `${pkg.baseUrl}product/getAllProductNames`;

export const allBlogApi = `${pkg.baseUrl}blog/getAllblogwebsite`;
export const singleBlogApi = `${pkg.baseUrl}blog/getSingleBlog`;
export const userCount = `${pkg.baseUrl}admin/visitors`;
export const repeatMedicineApi = `${pkg.baseUrl}user/repeatMedicine`;

// signup api  endpoints
export const sendOtpToUserApi = `${pkg.baseUrl}user/send-otp`;
export const verifyOtpApi = `${pkg.baseUrl}user/verify-otp`;
export const signupApi = `${pkg.baseUrl}user/signUpUser`;
export const userAddressApi = `${pkg.baseUrl}user/user-address`;
export const userAddressDeleteApi = `${pkg.baseUrl}user/delete-address`;
export const userAddressUpdateApi = `${pkg.baseUrl}user/update-address`;

export const returnApi = `${pkg.baseUrl}user/return-order`;
export const verificationEmailApi = `${pkg.baseUrl}user/send-email-verification`;

export const sendotpforforgotPassword = `${pkg.baseUrl}user/forgot-password-otp`;
export const forgotPasswordApi = `${pkg.baseUrl}user/forgot-password`;

// add review
export const addReviewApi = `${pkg.baseUrl}user/addRatingReview`;

export const labHistoryApi = `${pkg.baseUrl}lab/getAllLabOrder`;
export const cancelLabBookingApi = `${pkg.baseUrl}lab/updateLaborder`;

export const consultancyHistoryApi = `${pkg.baseUrl}user/getbookings`;
export const cancelConsultancyApi = `${pkg.baseUrl}admin/updatebooking`;

export const labPackageApi = `${pkg.baseUrl}lab/get-lab-package`;
export const labPackageBooking = `${pkg.baseUrl}user/bookLabPackage`;
