import React, { useEffect, useState } from "react";
import "../../styles/BuyNowPage/BuyNowPage.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { buyNowApi } from "../../Constents/apiAxios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../Assets/Images/logo/head.png";
import { fetchUserAddresses } from "../../store/signupSlice/userAddress";
import { clearCart, clearCartAsync } from "../../store/newCart/newCart";
import { clearNewBuyNow } from "../../store/newBuyNow/newBuyNow";

function AddressForm({
    pricingDetails,
    formData,
    setNewFormData,
    newFormData,
    isNewAddress,
    handleToggle,
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let requestData;
    const user = useSelector((state) => state?.login?.user);
    const userToken = localStorage.getItem("authToken");

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const buyNow = useSelector((state) => state.newBuyNow.newBuyNow);

    // handler for checkbox
    const handleCheckboxChange = (event) => {
        setSelectedPaymentMethod(event.target.id);
    };

    // Function to format full name
    const formatFullName = (name) => {
        return name.replace(/\b\w+/g, (word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
    };

    // handler for input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        const capitalizedValue = formatFullName(value);
        setNewFormData({
            ...newFormData,
            [name]: (name === "fullName" || name === "addressLine1" || name === "addressLine2" || name === "city" || name === "state" || name === "country") ? capitalizedValue : value,
        });
    };

    // regex for validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/; // Change here: limit to Gmail and Yahoo
    const phoneNumberRegex = /^\+?\d{10}$/;
    const fullNameRegex = /^[A-Z][a-z]*\s[A-Z][a-z]*\s[A-Z][a-z]*$/; // Format: First Last Middle

    // handler for submit form
    const handleSubmit = async (e) => {
        e.preventDefault();

        // if (!fullNameRegex.test(newFormData.fullName || formData.fullName)) {
        //     toast.error("Full name must be in the format: First Last Middle, with each part capitalized.");
        //     return;
        // }

        if (!emailRegex.test(newFormData.email.toLowerCase() || formData.email.toLowerCase())) {
            toast.error("Email must be a valid Gmail or Yahoo address");
            return;
        }

        if (!phoneNumberRegex.test(newFormData.mobileNo || formData.mobileNo)) {
            toast.error("Invalid phone number format");
            return;
        }

        setIsSubmitting(true);

        if (!userToken) {
            setIsSubmitting(false);
            return;
        }

        const requiredFields = [
            "fullName",
            "mobileNo",
            "email",
            "addressLine1",
            "pinCode",
            "city",
            "state",
        ];

        const missingFields = requiredFields.filter(
            (field) => !(isNewAddress ? newFormData[field] : formData[field])
        );

        if (missingFields.length > 0) {
            const missingFieldsString = missingFields.join(", ");
            setIsSubmitting(false);
            toast.error(
                `Please fill the following fields: ${missingFieldsString}`
            );
            return;
        }

        const products1 = [];
        // populate the product
        buyNow.forEach((item) => {
            products1.push({
                productId: item?.productId,
                productVariantId: item?.productVariantId,
                sellingPrice: item?.sellingPrice,
                brand: item?.brand,
                productName: item.productName,
                potency: item?.potency,
                size: item?.size,
                productImage: item?.imageObj?.url,
                quantity: item?.quantity,
                discount: item?.discount,
                mrp: item?.mrp
            });
        });

        // requested data to send with api
        requestData = {
            products: products1,
            totalPrice: pricingDetails?.totalPrice,
            discountPrice: pricingDetails?.totalDiscountPrice,
            shippingPrice: pricingDetails?.totalShippingPrice,
            finalPrice: pricingDetails?.totalPay,
            newAddress: newFormData,
            address: isNewAddress ? newFormData : formData,
        };

        if (selectedPaymentMethod === "pay") {
            const priceToPay = pricingDetails?.totalPay - (pricingDetails?.totalPay * 5) / 100;
            requestData = { ...requestData, finalPrice: priceToPay.toFixed(2), AdditionalDiscountPrice: ((pricingDetails?.totalPay * 5) / 100).toFixed(2) };

            try {
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: Math.round(priceToPay * 100), // Convert to paise
                    currency: "INR",
                    name: "sahas homeo",
                    description: "Test payment",
                    image: logo,
                    handler: async (response) => {
                        requestData = {
                            ...requestData,
                            paymentgatway: response,
                            type: "online",
                            paid: true,
                        };

                        try {
                            const response = await axios.post(
                                buyNowApi,
                                requestData,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${userToken}`,
                                    },
                                }
                            );

                            if (response?.data?.status === "success") {
                                toast.success(`${response?.data?.message}`);
                                dispatch(clearCart())
                                dispatch(clearNewBuyNow())
                                dispatch(clearCartAsync())
                                localStorage.removeItem("newcart")
                                navigate("/orderConfirmed");
                                dispatch(fetchUserAddresses(userToken));
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.message  || "Server error");
                            if(error?.response?.status === 401){
                                localStorage.removeItem("authToken");
                                navigate("/");
                            }
                        }
                    },

                    // prefill data of user
                    prefill: {
                        name: `${user?.firstName} ${user?.lastName}`,
                        email: user?.email,
                        contact: user?.phone,
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
                setIsSubmitting(false);
            } catch (error) {
                toast.error("Failed to create order");
                setIsSubmitting(false);
            }
        } else if (selectedPaymentMethod === "payOnDelivery") {
            requestData = {
                ...requestData,
                type: "cod",
                paid: false,
            };

            try {
                const response = await axios.post(buyNowApi, requestData, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                });

                if (response?.data?.status === "success") {
                    toast.success(`${response?.data?.message}`);
                    dispatch(clearCart())
                    dispatch(clearCartAsync())
                    dispatch(clearNewBuyNow())
                    localStorage.removeItem("newcart")
                    navigate("/orderConfirmed");
                    dispatch(fetchUserAddresses(userToken));
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || "Server error");
                setIsSubmitting(false);
                if(error?.response?.status === 401){
                    localStorage.removeItem("authToken");
                    navigate("/");
                }
            }
        }
    };

    return (
        <div className="my_order_sec_right">
            <div className="my_order_right_heading">
                <button onClick={handleToggle} className="address_form_add_btn">
                    Select from Existing Address
                </button>
                <h2>Shipping Address Details</h2>
                <p>Complete Your Purchase Items By Providing Details</p>
            </div>

            <form className="my_order_form" onSubmit={handleSubmit}>
                <div className="my_order_right_input">
                    <label htmlFor="fullName">Your Full Name</label>
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        placeholder="Enter Your Full Name"
                        value={
                            isNewAddress ? newFormData?.fullName : formData?.fullName
                        }
                        onChange={handleChange}
                        readOnly={!isNewAddress}
                    />
                </div>
                <div className="my_order_two_input">
                    <div className="my_order_right_input">
                        <label htmlFor="mobileNo">Mobile No.</label>
                        <input
                            type="tel"
                            id="mobileNo"
                            name="mobileNo"
                            placeholder="Enter Mobile No."
                            value={
                                isNewAddress ? newFormData?.mobileNo : formData?.mobileNo
                            }
                            onChange={handleChange}
                            readOnly={!isNewAddress}
                        />
                    </div>
                    <div className="my_order_right_input">
                        <label htmlFor="email">Enter Email id</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter Email id"
                            value={
                                isNewAddress ? newFormData?.email : formData?.email
                            }
                            onChange={handleChange}
                            readOnly={!isNewAddress}
                        />
                    </div>
                </div>
                <div className="my_order_right_input">
                    <label htmlFor="addressLine1">Address Line 1</label>
                    <input
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        placeholder="Enter Your House No/Flat No./ Building Number"
                        value={
                            isNewAddress
                                ? newFormData?.addressLine1
                                : formData?.addressLine1
                        }
                        onChange={handleChange}
                        readOnly={!isNewAddress}
                    />
                </div>
                <div className="my_order_right_input">
                    <label htmlFor="addressLine2">Address Line 2</label>
                    <input
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        placeholder="Enter Your Town?Land Mark Here"
                        value={
                            isNewAddress
                                ? newFormData?.addressLine2
                                : formData?.addressLine2
                        }
                        onChange={handleChange}
                        readOnly={!isNewAddress}
                    />
                </div>
                <div className="my_order_two_input">
                    <div className="my_order_right_input">
                        <label htmlFor="pinCode">Pin Code</label>
                        <input
                            type="text"
                            id="pinCode"
                            name="pinCode"
                            placeholder="Enter Pin Code"
                            value={
                                isNewAddress ? newFormData?.pinCode : formData?.pinCode
                            }
                            onChange={handleChange}
                            readOnly={!isNewAddress}
                        />
                    </div>
                    <div className="my_order_right_input">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Enter City"
                            value={
                                isNewAddress ? newFormData?.city : formData?.city
                            }
                            onChange={handleChange}
                            readOnly={!isNewAddress}
                        />
                    </div>
                </div>
                <div className="my_order_two_input">
                    <div className="my_order_right_input">
                        <label htmlFor="state">State</label>
                        <input
                            type="text"
                            id="state"
                            name="state"
                            placeholder="Enter State"
                            value={
                                isNewAddress ? newFormData?.state : formData?.state
                            }
                            onChange={handleChange}
                            readOnly={!isNewAddress}
                        />
                    </div>
                    <div className="my_order_right_input">
                        <label htmlFor="country">Country</label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            placeholder="India"
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                </div>
                <div className="my_order_payment">
                    <div>
                        <h1>Select Mode of Payment</h1>
                    </div>

                    <div className="my_order_payment_input">
                        <input
                            type="checkbox"
                            id="pay"
                            checked={selectedPaymentMethod === "pay"}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="pay">
                            Pay Now (to get 5% Off ){" "}
                            <span>Using Debit/Credit Card or UPI</span>
                        </label>
                    </div>

                    <div className="my_order_payment_input">
                        <input
                            type="checkbox"
                            id="payOnDelivery"
                            checked={selectedPaymentMethod === "payOnDelivery"}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="payOnDelivery">Cash on Delivery</label>
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting.." : "Proceed"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default React.memo(AddressForm);
